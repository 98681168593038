<template>
    <div class="hiw">
        <div class="hiw-wrapper container">
            <h2>{{ $t('how_it_works') }}</h2>
            <iframe height="500" :src="youtubeLink" class="vid"></iframe> 
            
            <div class="slider">
                <p class="title">{{ $t('step_by_step') }}</p>
                <VueSlickCarousel v-bind="settings" class="how-slide">
                    <div
                        class="card"
                        style="width: 18rem;"
                        v-for="step in steps"
                        :key="step.number"
                    >
                        <p class="number">{{ step.number }}</p>
                        <div class="card-body">
                            <h3 class="card-title">{{ step[`title_${language}`] }}</h3>
                            <p class="card-desc">{{ step[`text_${language}`] }}</p>
                        </div>
                    </div>

                    <template #prevArrow="">
                        <div class="custom-arrow">
                            <!-- {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }} -->
                            <img :src="require('@/assets/img/left-arrow.png')" alt="">
                        </div>
                    </template>

                    <template #nextArrow="">
                        <div class="custom-arrow">
                            <!-- {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }} -->
                            <img :src="require('@/assets/img/right-arrow.png')" alt="">
                        </div>
                    </template>
                </VueSlickCarousel>
            </div>
        </div>
    </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";

export default {
    data () {
        return {
            steps: [
                {
                    number: "01",
                    title_en: "Choose cards",
                    title_cn: "選擇卡牌",
                    text_en: "Out of the 54 display cards, choose 8 cards with activities that you feel most connected to in daily life.",
                    text_cn: "從54張展示的卡牌當中，選擇八張您最喜歡從事的日常生活"
                },
                {
                    number: "02",
                    title_en: "Select reasons",
                    title_cn: "選擇原因",
                    text_en: "From the cards you choose, pick all the reasons why you like to do these activities.",
                    text_cn: "從您選擇的卡牌中，挑選出您喜歡做這件事情的原因"
                },
                {
                    number: "03",
                    title_en: "Evaluation",
                    title_cn: "評估",
                    text_en: "The system will automatically classify the given score according to the reasons you choose, while a FindLINC hard copy needs to be scored manually with a scorecard.",
                    text_cn: "根據您所選擇的原因，線上牌卡會自動幫您歸類計分，紙本牌卡則需用計分卡手動計分"
                },
                {
                    number: "04",
                    title_en: "Result",
                    title_cn: "結果",
                    text_en: "The assessment results with the highest accumulated score can further understand the appropriate field of study and relevant career development suggestions.",
                    text_cn: "累積最高分的類型，可以進一步了解適合學習的領域及相關職業發展建議"
                }
            ],
            
            settings: {
                arrows: true,
                focusOnSelect: true,
                infinite: false,
                speed: 500,
                slidesToShow: 4,
                slidesToScroll: 1,
                touchThreshold: 5,
                responsive: [
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 576,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            }
        }
    },

    components: {
        VueSlickCarousel
    },

    computed: {
        ...mapFields(['language']),
        
        youtubeLink () {
            if (this.language === 'en') return 'https://youtube.com/embed/yygonVcNo-M'
            return 'https://youtube.com/embed/HAxpKR2GWCA'

            // return 'https://youtube.com/embed/yygonVcNo-M'
        }
    }
}
</script>

<style lang="scss" scope>
.hiw {
    color: #221E1F;
    // margin-bottom: 190px;

    @include desktop {
        margin-bottom: 190px;
    }

    .hiw-wrapper {
        // width: 1200px;
        text-align: left;

        @include desktop {
            width: 1200px;
        }

        h2 {
            text-align: center;
            font-family: 'Crimson Pro', sans-serif;
            font-weight: 700;
            font-size: 34px;
            padding-top: 30px;
            padding-bottom: 30px;
            border-bottom: 1px solid #B8B8B8;
            margin-bottom: 20px;
        }

        .vid {
            width: 100%;

            @include desktop {
                margin-bottom: 50px;
            }
        }
        
        .slider {
            position: relative;
            margin-top: 35px;
            margin-bottom: 35px;

            @include desktop {
                margin-top: 60px;
                margin-bottom: 150px;
            }

            .title {
                font-family: 'Crimson Pro', sans-serif;
                font-weight: 700;
                font-size: 28px;
                margin-bottom: 30px;
            }

            

            .how-slide {
                .card {
                    padding-right: 1em;
                    
                    .number {
                        font-family: 'Crimson Pro', sans-serif;
                        font-weight: 600;
                        font-size: 20px;
                        color: #B8B8B8;
                        margin-bottom: 18px;
                    }

                    .card-body {
                        padding: 0;

                        .card-title {
                            font-family: 'Crimson Pro', sans-serif;
                            font-weight: 700;
                            font-size: 20px;
                            margin-bottom: 15px;
                        }

                        .card-desc {
                            font-size: 18px;;
                            color: #B8B8B8;
                            line-height: 26px;
                        }
                    }
                }

                .slick-disabled {
                    opacity: 0.3;
                    pointer-events: none;
                }
                
                .slick-next {
                    position: absolute;
                    top: -55px;
                    z-index: 1;
                    right: 0;
                    // visibility: hidden;
                    background: transparent;
                    border: transparent;
                    color: transparent;

                    img {
                        cursor: pointer;
                    }

                    // &:after {
                    //     background-image: url('~@/assets/img/right-arrow.png');
                    //     background-repeat: no-repeat;
                    //     // color: #212529;
                    //     content: "\2192";
                    //     visibility: visible;
                    //     display: flex;
                    //     position: absolute;
                    //     top: 0px;
                    //     // right: 0;
                    //     font-size: 5em;
                    //     // max-height: 30px;
                    //     height: 50%;
                    //     // line-height: 0em;
                    // }
                }

                .slick-prev {
                    position: absolute;
                    top: -55px;
                    z-index: 1;
                    right: 4em;
                    // visibility: hidden;
                    background: transparent;
                    border: transparent;
                    color: transparent;

                    @include desktop {
                        right: 7em;
                    }

                    img {
                        cursor: pointer;
                    }

                    // &:after {
                    //     background-image: url('~@/assets/img/left-arrow.png');
                    //     background-repeat: no-repeat;
                    //     // color: #212529;
                    //     content: "\2192";
                    //     visibility: visible;
                    //     display: flex;
                    //     position: absolute;
                    //     top: 0px;
                    //     font-size: 5em;
                    //     // max-height: 30px;
                    //     height: 100%;
                    //     // line-height: 0em;
                    // }
                }
            }

            .slick-disabled {
                opacity: 0.5;
                pointer-events: none;
            }
        }
    }
}
</style>